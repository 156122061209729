
import Vue from "vue";
import moment from "moment";

//class
import { DailyWorkReportKey } from "@/models/dailyWorkReport/DailyWorkReportKey";
import {
  Isolation,
  IsolationText,
  IsolationTableHeaders,
  IsolationTable
} from "@/models/dailyWorkReport/Isolation";
import { CodeKbnList } from "@/models/codeMaster/Code";
import {
  PulldownResponse,
  codeKbnFilter
} from "@/models/response/PulldownResponse";
import {
  CheckboxGroupItems,
  pulldownToCheckItems
} from "@/components/input/CheckboxGroup.vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

// Components
import { Headers } from "@/components/input/DailyWorkReportInput.vue";
import DailyWorkReportFormDialog from "@/components/dialog/DailyWorkReportFormDialog.vue";
import DataIterators from "@/components/table/dailyworkreport/dataiterators/DataIterators.vue";
import Snackbar from "@/components/dialog/Snackbar.vue";
import Overlay from "@/components/dialog/Overlay.vue";
import { ShainSearchRequest } from "@/models/ShainSearchRequest";
import Shain from "@/models/Shain";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { OrderDetailsSearchResponse } from "@/models/orderDetails/response/OrderDetailsSearchResponse";
import { DATE_FORMAT } from "@/defines";
import SpecDocsDailog from "@/components/dialog/specdocs/SpecDocsDailog.vue";
import Store from "@/store/index";

const DATE = moment().format(DATE_FORMAT.DATE);
const YEAR_SUFFIX = moment().format(DATE_FORMAT.YEAR_SUFFIX);

export default Vue.extend({
  name: "Isolation",
  mixins: [Common, ShowDialogs, Api],
  components: {
    DailyWorkReportFormDialog,
    DataIterators,
    Snackbar,
    Overlay,
    SpecDocsDailog
  },
  props: {
    date: {
      type: String,
      required: false,
      default: DATE
    },
    machineCode: {
      type: String,
      required: false,
      default: null
    },
    machineName: {
      type: String,
      required: false,
      default: null
    },
    form: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      selectDate: this.date,
      isAdd: false,
      isOpen: false,
      maxRecord: 30,
      tableHeaders: IsolationTableHeaders,
      editRecord: new Isolation(new DailyWorkReportKey({ workDate: DATE })),
      reportData: [] as Isolation[], //テーブルデータ
      mainTitle: "絶縁押出作業記録",
      tableName: "Isolation",
      isLoading: false,
      userItems: [] as CheckboxGroupItems[], //作業者
      machineCodeItems: [] as CheckboxGroupItems[], //絶縁機械名
      insertTapeItems: [] as CheckboxGroupItems[], //挿入テープ種類
      emarkItems: [] as CheckboxGroupItems[], //<PS>Eマーク
      separateTypeItems: [] as CheckboxGroupItems[], //セパレータ種類
      isView: false,
      fileUrl: "",
      acceptNoSuffixSize: 5
    };
  },
  computed: {
    title() {
      return `${this.mainTitle}： ${this.machineName}`;
    },
    headers() {
      return [
        { ...IsolationText.workDate, type: "date-picker", disabled: true },
        {
          ...IsolationText.machineCode,
          type: "select",
          items: this.machineCodeItems,
          disabled: true,
          default: this.machineCode
        },
        {
          ...IsolationText.userId,
          type: "select",
          items: this.userItems,
          required: true
        },
        { ...IsolationText.inspectionFlag, type: "checkbox" },
        {
          ...IsolationText.acceptNoPrefix,
          type: "text",
          maxLength: 2
        },
        {
          ...IsolationText.acceptNoSuffix,
          type: "text",
          maxLength: this.acceptNoSuffixSize,
          search: true
        },
        { ...IsolationText.successFlag, type: "checkbox" },
        { ...IsolationText.specificationNo, type: "text", maxLength: 40 },
        { ...IsolationText.conductorType, type: "text", maxLength: 80 },
        { ...IsolationText.itemName, type: "text", maxLength: 80 },
        { ...IsolationText.size, type: "text", maxLength: 80 },
        { ...IsolationText.color, type: "text", maxLength: 40 },
        { ...IsolationText.extrusionQuantity, type: "text", maxLength: 80 },
        {
          ...IsolationText.separateType,
          type: "combobox",
          items: this.separateTypeItems,
          maxLength: 80
        },
        {
          ...IsolationText.separateThickness,
          type: "number",
          digit: 10,
          scale: 2
        },
        { ...IsolationText.separateWidth, type: "number", digit: 10, scale: 2 },
        {
          ...IsolationText.nippleDiameter,
          type: "number",
          digit: 10,
          scale: 2
        },
        { ...IsolationText.diceDiameter, type: "number", digit: 10, scale: 2 },
        { ...IsolationText.outerDiameter, type: "number", digit: 10, scale: 2 },
        { ...IsolationText.isThicknessDeviation, type: "checkbox" },
        {
          ...IsolationText.insertTape,
          type: "checkbox-group",
          items: this.insertTapeItems
        },
        {
          ...IsolationText.eMark,
          type: "checkbox-group",
          items: this.emarkItems
        },
        { ...IsolationText.conductorLotNo1, type: "text", maxLength: 80 },
        { ...IsolationText.conductorLotNo2, type: "text", maxLength: 80 },
        { ...IsolationText.conductorLotNo3, type: "text", maxLength: 80 },
        { ...IsolationText.coatingLotNo1, type: "text", maxLength: 80 },
        { ...IsolationText.coatingLotNo2, type: "text", maxLength: 80 },
        { ...IsolationText.coatingLotNo3, type: "text", maxLength: 80 },
        { ...IsolationText.abnormality, type: "textarea", maxLength: 255 },
        { ...IsolationText.biko, type: "textarea", maxLength: 255 }
      ] as Headers[];
    },
    tableRecord() {
      const records: Isolation[] = this.reportData;
      const result = records.map((y: Isolation) => {
        const record = new IsolationTable(y);
        const user = this.userItems.find(e => e.value === y.userId);
        const date = moment(y.updateTs).format(DATE_FORMAT.DATE_MINUTE);
        record.updateTs = date;
        record.userId = user ? user.text : null;
        return record;
      });
      return result;
    }
  },
  methods: {
    /**
     * 追加
     */
    onAdd() {
      console.log("add");
      this.isAdd = true;
      if (this.reportData.length >= this.maxRecord) {
        this.snackbarText = `${this.maxRecord}列以上は登録できません。`;
        this.snackbar = true;
        return;
      }
      const reocrdKey = new DailyWorkReportKey({ workDate: this.selectDate });
      this.editRecord = new Isolation(reocrdKey);
      this.editRecord.acceptNoPrefix = YEAR_SUFFIX;
      this.isOpen = true;
    },
    /**
     * 編集
     * @param value index
     */
    onEdit(value: number) {
      console.log("edit");
      this.isAdd = false;
      this.editRecord = Object.assign({}, this.reportData[value]);
      this.editRecord.acceptNoPrefix =
        this.editRecord.acceptNo?.slice(0, -this.acceptNoSuffixSize) ?? null;
      this.editRecord.acceptNoSuffix =
        this.editRecord.acceptNo?.slice(-this.acceptNoSuffixSize) ?? null;
      this.isOpen = true;
    },
    /**
     * コピー
     * @param value DailyWorkReportSearchResponse
     */
    async onCopy(value: DailyWorkReportSearchResponse, close: () => void) {
      console.log("copy", value);
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const param = new DailyWorkReportKey(value);
        const result = await api.$post<Isolation>(
          api.Paths.dailyWorkReport.isolation.searchKey,
          param
        );
        result.workDate = this.selectDate;
        result.machineCode = this.machineCode;
        this.editRecord = result;
        this.editRecord.acceptNoPrefix =
          this.editRecord.acceptNo?.slice(0, -this.acceptNoSuffixSize) ?? null;
        this.editRecord.acceptNoSuffix =
          this.editRecord.acceptNo?.slice(-this.acceptNoSuffixSize) ?? null;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("copy end");
        this.isLoading = false;
      }
    },
    async onFind(value: OrderDetailsSearchResponse, close: () => void) {
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        if (value.alreadyAssigned === null) {
          showDialogs.$error("受注番号の登録がありません。");
        } else {
          if (value.alreadyAssigned) {
            showDialogs.$error("既に他の作業日報データで引用されています。");
            value.juhno = null;
            value.syno = null;
            value.shonm = null;
            value.kikaku = null;
            value.denno = null;
          }
        }
        this.editRecord.acceptNo = value.juhno;
        this.editRecord.acceptNoSuffix = value.juhno
          ? value.juhno.slice(-this.acceptNoSuffixSize)
          : null;
        this.editRecord.specificationNo = value.syno;
        this.editRecord.itemName = value.shonm;
        this.editRecord.size = value.kikaku;
        this.editRecord.denno = value.denno;
        this.editRecord.gyono = value.gyono;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
      }
    },
    async onView(index: number) {
      const api = new Api();
      this.fileUrl = `${process.env.VUE_APP_API_BASE_URL}${api.Paths.specDocs.searchkey}?token=${Store.state.mtoken}&denno=${this.reportData[index].denno}&gyono=${this.reportData[index].gyono}`;
      this.isView = true;
    },
    // ==============================
    // 検索
    // ==============================
    async onSearch() {
      console.log("onSearch start");
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        this.reportData = await this.getIsolationRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSearch end");
        this.isLoading = false;
      }
    },
    // ==============================
    // 保存
    // ==============================
    async onSave(saveValue: Isolation) {
      console.log("onSave start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      this.isLoading = true;
      try {
        if (this.isAdd) {
          await api.$post(
            api.Paths.dailyWorkReport.isolation.insert,
            saveValue
          );
        } else {
          await api.$post(
            api.Paths.dailyWorkReport.isolation.update,
            saveValue
          );
        }
        showDialogs.$info("更新しました。", "更新");
        this.reportData = await this.getIsolationRecord();
        this.isOpen = false;
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSave end");
        this.isLoading = false;
      }
    },
    /**
     * 削除
     * @param index
     */
    async onDelete(index: number) {
      console.log("onDelete start", index);
      const api = new Api();
      const showDialogs = new ShowDialogs();
      const title = "確認";
      const message = `${index +
        1}列目の作業記録を削除します。\nよろしいですか？`;
      const confirm = await showDialogs.$deleteConfirm(message, title);
      if (!confirm) return;

      this.isLoading = true;
      try {
        const target = this.reportData[index];
        await api.$post(api.Paths.dailyWorkReport.isolation.delete, target);
        showDialogs.$info("削除しました。", "情報");
        this.reportData = await this.getIsolationRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onDelete end");
        this.isLoading = false;
      }
    },
    /**
     * 絶縁作業記録データ取得
     */
    async getIsolationRecord() {
      const api = new Api();
      const param = {
        workDate: new Date(this.selectDate),
        machineCode: this.machineCode
      };
      const result = await api.$post<Isolation[]>(
        api.Paths.dailyWorkReport.isolation.search,
        param
      );
      return result;
    },
    /**
     * データベースから選択項目データ取得
     */
    async getItems() {
      console.log("getItems start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      try {
        //apiパス
        const userPath = api.Paths.shainSearch;
        const codeMasterPath = api.Paths.codeMasterPulldown;

        //リクエスト
        const shainRequest = new ShainSearchRequest({
          department: [this.$store.state.dailyWorkReportStore.nippoType],
          isValid: "1"
        });
        const userPromise = api.$post<Shain[]>(userPath, shainRequest);
        const codeMasterPromise = api.$get<PulldownResponse[]>(codeMasterPath);
        const [user, codeMaster] = await Promise.all([
          userPromise,
          codeMasterPromise
        ]);

        //ユーザデータ取得
        this.userItems = user.map(e => ({ value: e.userId, text: e.userName }));
        //コードマスタのデータをすべて取得
        //絶縁機械名
        const machine = codeKbnFilter(codeMaster, CodeKbnList.OSDS);
        this.machineCodeItems = pulldownToCheckItems(machine);
        //挿入テープ種類
        const insertTape = codeKbnFilter(codeMaster, CodeKbnList.INTAPE);
        this.insertTapeItems = pulldownToCheckItems(insertTape);
        //<PS>Eマーク
        const emarkCheck = codeKbnFilter(codeMaster, CodeKbnList.YESNO);
        this.emarkItems = pulldownToCheckItems(emarkCheck);
        //セパレート種類
        const separateType = codeKbnFilter(codeMaster, CodeKbnList.SEPATP);
        this.separateTypeItems = pulldownToCheckItems(separateType);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
      }
    },
    /**
     * テーブルコード取得
     */
    async getCode() {
      console.log("getCode start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      try {
        //apiパス
        const path = api.Paths.dailyWorkReport.isolation.code;

        //リクエスト
        const code = await api.$get<string>(path);

        this.$store.commit("dailyWorkReportStore/nippoTypeSet", code);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
      }
    }
  },

  async created() {
    this.$watch(
      () => this.$route.params,
      async () => {
        await this.onSearch();
      },
      { immediate: true }
    );

    await this.getCode();
    await this.getItems();
  }
});
